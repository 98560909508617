<template>
    <div class="page formPage" id="exhibition">
        <el-row :gutter="12">
            <el-col :span="4">
                <el-input clearable placeholder="请输入展览分类名称" size="small" v-model="culturalName"></el-input>
            </el-col>
            <el-col :span="5">
                <el-button size="small" icon="el-icon-search"  type="primary" @click="getList(1)">查询</el-button>
                <el-button size="small" icon="el-icon-refresh-right" @click="reselt()">重置</el-button>
            </el-col>
            <el-col :span="15" style="text-align: right">
                <el-button v-if="hasPermission('management:exhibitionClassification:add')" size="small" type="primary"
                           @click="details(0,2)">新增
                </el-button>
                <el-button :disabled="multipleSelectionIds.length == 0"
                           v-if="hasPermission('management:exhibitionClassification:delete')"
                            size="small"
                           type="primary"
                           @click="Wudelete()">删除
                </el-button>
            </el-col>
        </el-row>
        <el-table
                :data="dataList"
                v-loading="loading"
                size="small"
                height="calc(100% - 90px)"
                @selection-change="handleSelectionChange"
                class="table">
            <el-table-column
                    type="selection"
                    width="55">
            </el-table-column>
            <el-table-column prop="classificationName" label="分类名称" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="enabledState" label="状态" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-switch
                            v-model="scope.row.enabledState"
                            active-value="1"
                            inactive-value="0"
                            @change="stateChange(scope.row)"
                    >
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="createUser" label="操作人" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="createTime" label="添加时间" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="updateTime" label="最后修改时间" show-overflow-tooltip>
            </el-table-column>
            <el-table-column fixed="right" width="200" label="操作">
                <template slot-scope="scope">
                    <!--                    <el-button-->
                    <!--                            v-if="hasPermission('management:exhibitionList:see')"-->
                    <!--                            icon="el-icon-view" type="text"-->
                    <!--                            size="mini" @click="details(scope.row.id,0)">详情-->
                    <!--                    </el-button>-->
                    <el-button
                            v-if="hasPermission('management:exhibitionClassification:edit')"
                            icon="el-icon-edit" type="text"
                            size="mini" @click="details(scope.row.id,1)">编辑
                    </el-button>
                    <el-button
                            v-if="scope.row.enabledState != 1 && hasPermission('management:exhibitionClassification:delete')"
                            icon="el-icon-delete" type="text"
                            size="mini" @click="Wudelete(scope.row.id)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                :current-page="pageNo"
                :page-size="pageSize"
                :page-sizes="[10, 20, 50, 100]"
                :total="total"
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                background
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>

        <!--新增编辑-->
        <el-dialog
                :title="types == 1 ? '编辑展览分类' : '新增展览分类'"
                :visible.sync="dialogVisible"
                width="30%"
                :close-on-click-modal="false"
                :before-close="handleClose">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" size="small" label-width="100px" class="demo-ruleForm">
                <el-form-item label="分类名称" prop="classificationName">
                    <el-input maxlength="20" show-word-limit style="width: 85%" v-model="ruleForm.classificationName"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="enabledState">
                    <el-switch
                            v-model="ruleForm.enabledState"
                            active-value="1"
                            inactive-value="0"
                    >
                    </el-switch>
                </el-form-item>
                <div style="text-align: center">
<!--                    v-if="hasPermission('management:exhibitionClassification:submit')"-->
                    <el-button size="small" :loading="tijiao" type="primary" @click="submitForm('ruleForm')">提交</el-button>
                    <el-button size="small" @click="resetForm('ruleForm')">取 消</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "exhibition",
        data() {
            return {
                culturalName: '',//展厅名称
                dataList: [],
                loading: false,
                pageNo: 1,
                pageSize: 10,
                total: 0,
                distinguish: 0,
                multipleSelectionIds: [],//展厅多选
                dialogVisible: false,
                ruleForm:{
                    id:'',
                    classificationName:'',
                    enabledState:'0',
                },
                rules:{
                    classificationName: [
                        { required: true, message: '请填写分类名称', trigger: 'blur' },
                    ],
                },
                types:1,// 1编辑，2新增
                tijiao:false,
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            //获取列表
            getList(num) {
                this.loading = true;
                if (num) {
                    this.pageNo = 1;
                }
                this.$axios(this.api.zhanLanMessage.list, {
                    classificationName: this.culturalName,
                    current: this.pageNo,
                    size: this.pageSize,
                }, 'post').then(res => {
                    this.loading = false;
                    this.dataList = res.data.records;
                    this.total = parseInt(res.data.total)
                })
            },

            reselt(){
                this.culturalName = ''
                this.getList(1)
            },

            //num  1编辑,2新增
            details(id, num) {
                this.types = num;
                if (num == 1){
                    this.$axios(this.api.zhanLanMessage.getById + id, {}, 'get').then(res => {
                        if (res.status) {
                            this.ruleForm.id = res.data.id
                            this.ruleForm.classificationName = res.data.classificationName
                            this.ruleForm.enabledState = res.data.enabledState
                        }
                    })
                }
                this.dialogVisible = true;
            },

            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.getList();
            },

            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val;
                this.getList();
            },


            //多选
            handleSelectionChange(val) {
                this.multipleSelectionIds = []
                val.forEach(item => {
                    if (item.enabledState != 1){
                        this.multipleSelectionIds.push(item.id)
                    }
                })
                console.log(this.multipleSelectionIds)
            },

            //关闭弹窗
            handleClose() {
                this.dialogVisible = false;
            },

            //修改状态
            stateChange(obj) {
                this.$axios(this.api.zhanLanMessage.updateById ,{
                    classificationName:obj.classificationName,
                    enabledState:obj.enabledState,
                    id:obj.id,
                }, 'put').then((res)=>{
                    if (res.status){
                        this.$message.success('修改成功')
                        this.getList();
                    }else {
                        this.$message.error(this.msg)
                    }
                })
            },

            //删除
            Wudelete(id) {
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let ids = []
                    if (id) {
                        ids.push(id)
                    } else {
                        ids = this.multipleSelectionIds
                    }
                    this.$axios(this.api.zhanLanMessage.batchRemove, ids, 'POST').then(res => {
                        if (res.status) {
                            this.$message.success('删除成功');
                            if (this.dataList.length == ids.length && this.pageNo > 1){
                                this.pageNo--;
                            }
                            this.getList();
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                }).catch(() => {
                });
            },

            //提交
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.ruleForm.classificationName.trim() == ''){
                            this.$message.error('请填写分类名称')
                            return
                        }
                        this.tijiao = true
                        if (this.ruleForm.id != ''){ //编辑
                            this.$axios(this.api.zhanLanMessage.updateById ,{
                                ...this.ruleForm,
                            }, 'put').then((res)=>{
                                if (res.status){
                                    this.$message.success('修改成功')
                                    this.resetForm(formName);
                                    this.getList();
                                }else {
                                    this.$message.error(res.msg)
                                }
                                this.tijiao = false
                            })
                        }else{ //新增
                            this.$axios(this.api.zhanLanMessage.save,{
                                classificationName: this.ruleForm.classificationName,
                                enabledState: this.ruleForm.enabledState,
                            }, 'post').then((res)=>{
                                if (res.status){
                                    this.$message.success('保存成功')
                                    this.resetForm(formName);
                                    this.getList();
                                }else {
                                    this.$message.error(res.msg)
                                }
                                this.tijiao = false
                            })
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },

            //取消
            resetForm(formName) {
                this.dialogVisible = false;
                this.$refs[formName].resetFields();
                this.ruleForm.id = ''
            }
        }
    }
</script>

<style scoped>
    .picture-format {
        font-size: 12px;
    }
</style>
