import { render, staticRenderFns } from "./exhibitionClassification.vue?vue&type=template&id=b8fe6046&scoped=true&"
import script from "./exhibitionClassification.vue?vue&type=script&lang=js&"
export * from "./exhibitionClassification.vue?vue&type=script&lang=js&"
import style0 from "./exhibitionClassification.vue?vue&type=style&index=0&id=b8fe6046&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8fe6046",
  null
  
)

export default component.exports